import { Badge, Calendar } from 'antd';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { useNoteContext } from '../NoteContext';

export const dateFormat = 'YYYY-MM-DD';
const NoteCalendarModule = () => {
	const { listHook, formHook, action } = useNoteContext();
	const [searchParams, setSearchParams] = useSearchParams();

	const todayObject = dayjs();
	const groupBy =
		listHook?.data?.data?.map((item) => {
			const fieldName = 'createdAt';

			return item;
		}) ?? [];

	console.log(groupBy);

	return (
		<>
			<Calendar
				cellRender={(current, info) => {
					if (info.type === 'date') {
						return (
							<ul className="events">
								{groupBy.map((item) => (
									<div
										key={item.noteId}
										className="hover:bg-blue-100"
										onClickCapture={() => {}}
									>
										<Badge status={item.type} text={item.title} />
									</div>
								))}
							</ul>
						);
					}

					return info.originNode;
				}}
				// monthCellRender={() => {}}

				value={dayjs()}
				onSelect={(value) => {
					// router.push({
					//   query: {
					//     ...router.query,
					//     today: value.format(dateFormat),
					//   },
					// });
				}}
			/>
		</>
	);
};

export default NoteCalendarModule;
